import React, { useState, useEffect, useCallback } from "react";
import queryString from "query-string";
import SEO from "../components/SEO";
import axios from "axios";
import { renderKlarnaSnippet } from "../utils/klarna";
import {
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  PageSectionHeader,
} from "../components/common";
import { PageHeaderImage } from "../components/common/PageHeaderImage";

const Checkout = () => {
  const [showCheckout, setShowCheckout] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [cardAmount, setCardAmount] = useState(null);

  const handleCheckout = useCallback(async () => {
    if (subscription && cardAmount) {
      const { data } = await axios.post(`/.netlify/functions/createOrder`, {
        subscription,
        cardAmount,
      });

      renderKlarnaSnippet(data?.html_snippet);
      setShowCheckout(true);
    }
  }, [cardAmount, subscription]);

  useEffect(() => {
    handleCheckout();
  }, [handleCheckout]);

  useEffect(() => {
    const { subscription, cardAmount } = queryString.parse(
      window.location.search.slice(1)
    );

    setCardAmount(cardAmount || 0);
    setSubscription(subscription || "school");
  }, []);

  return (
    <>
      <SEO title="Checkout" />

      <PageSectionWrapper style={{ minHeight: "1000px" }}>
        <PageSection bgColor="var(--pmbCararra)">
          <PageSectionBackdrop bgColor="var(--creamWhite)">
            <PageSectionHeader style={{ minHeight: "997px" }}>
              <PageHeaderImage />
              <div
                style={{ visibility: showCheckout ? "unset" : "hidden" }}
                id="klarna-checkout-container"
              />
            </PageSectionHeader>
          </PageSectionBackdrop>
        </PageSection>
      </PageSectionWrapper>
    </>
  );
};

export default Checkout;
